import { ssoUrl } from '@/config/env'
import { useUserStore } from '@/store/modules/user'
import { getSsoToken } from '@/utils/cookies'

const appToken = 'App-Token'
const companyKey = 'User-Company'

const SSO_APP_NAME = process.env.VUE_APP_SSO_APP
const SSO_AUTH_TYPE = process.env.VUE_APP_SSO_TYPE

// 单点登录地址
export const ssoPath = {
	// dev: 'http://localhost:9501'
	dev: 'http://sso.dev.itsmycar.cn',
	test: 'http://sso.test.itsmycar.cn',
	pre: 'http://sso.pre.itsmycar.cn',
	prod: 'http://sso.yiautos.com',
}

// 获取appToken
export function getAppToken() {
	return window.localStorage.getItem(appToken)
}

// 设置appToken
export function setAppToken(value) {
	const userStore = useUserStore()
	userStore.setToken(value)
}

// 删除appToken
export function removeAppToken() {
	return window.localStorage.removeItem(appToken)
}

// 跳转单点登录
export function jumpLogin({ appCode = SSO_APP_NAME, path = location.href } = {}) {
	let jumpUrl = ssoUrl.replace('api', '')
	location.href = `${jumpUrl}?appCode=${appCode}&redirect=${encodeURIComponent(path)}`
}

// 跳转单点登录不带回调参数
export function jumpLoginNoParam() {
	let jumpUrl = ssoUrl.replace('api', '')
	location.assign(jumpUrl)
}

// 获取url 上参数信息
export function getUrlParams() {
	const path = location.href
	if (path) {
		const queryArr =
			path
				.split('?')[1]
				?.replace(/(.+)(?=#)(.+)/, '$1')
				.split('&') || []
		const queryObj = {}
		queryArr.forEach(query => {
			const arr = query.split('=')
			Object.assign(queryObj, {
				[arr[0]]: decodeURIComponent(arr[1]),
			})
		})
		return queryObj
	}
	return {}
}

// url 中是否包含 appToken 参数
export function urlHasAppToken() {
	const params = getUrlParams()
	return !!params.appToken
}

// 从url 中解析appToken 并删除该token
export function readUrlAppToken() {
	const queryObj = getUrlParams()
	if (queryObj.appToken) {
		setAppToken(location.href.match(/appToken=(.{32})/)[1])
		const url = location.href
			.replace(/appToken=.{32}&?|\?appToken=.{32}(?!&)/, '')
			.replace(/(?<=#\/.*)((#\/)+)/, '')

		history.replaceState(null, '', url)
	}
}
/**
 * @description: 设置axios 请求头
 * @param {*} request
 * @param {*} appCode 应用code
 * @param {*} companyId
 * @return {*}
 */
export function setRequestHeader({ request, appCode = SSO_APP_NAME, companyId } = {}) {
	const appToken = getSsoToken()
	if (appToken && request?.headers) {
		request.headers['App-Token'] = appToken
		request.headers['App-Code'] = appCode
		request.headers['Auth-Type'] = SSO_AUTH_TYPE
		if (companyId) {
			request.headers['Company-Id'] = companyId
		} else {
			request.headers['Company-Id'] = 1
		}
	}
}
/**
 * @description: 筛选出权限路由，用于添加
 * @param {*} asyncRoutes 前端定义路由
 * @param {*} authRoutes 后端返回权限路由
 * @return {*}
 */
export function generateRoutes(asyncRoutes, authRoutes) {
	const flatAuthRoutes = flatTreeArr(authRoutes)
	return filterAsyncRoutes(asyncRoutes, flatAuthRoutes)
}
function filterAsyncRoutes(routes, authRoutes) {
	const res = []

	routes.forEach(route => {
		const tmp = { ...route }
		if (hasPermission(tmp, authRoutes)) {
			if (tmp.children) {
				tmp.children = filterAsyncRoutes(tmp.children, authRoutes)
			}
			res.push(tmp)
		}
	})
	return res
}
/**
 * @description: 设置用户所在公司
 * @param {*} company 公司对象
 * @return {*}
 */
export function setUserCompany(company) {
	sessionStorage.setItem(companyKey, JSON.stringify(company))
}
/**
 * @description: 获取公司
 * @param {*}
 * @return {*}
 */
export function getUserCompany() {
	const companyStr = sessionStorage.getItem(companyKey)
	if (companyStr) {
		return JSON.parse(companyStr)
	}
}
/**
 * @description: 删除公司数据
 * @param {*}
 * @return {*}
 */
export function removeUserCompany() {
	sessionStorage.removeItem(companyKey)
}

function hasPermission(route, authRoutes) {
	if (route.meta) {
		const authRoute = authRoutes.find(authRoute => route.meta.code === authRoute.code)
		if (authRoute) {
			Object.assign(route.meta, {
				ancestor: authRoute.ancestor,
			})
			// 若title 不在前端定义则采用后端传入菜单名
			if (!route.meta.selfTitle) {
				Object.assign(route.meta, {
					title: authRoute.name,
				})
			}
			return true
		}
		return false
	} else {
		return true
	}
}

// 将返回的菜单树格式化成普通一维 数组
function flatTreeArr(tree) {
	let queue = []
	if (Array.isArray(tree)) {
		queue = [...tree]
	} else if ({}.toString.call(tree) === '[object Object]') {
		queue = [tree]
	}
	let data = [] //返回的数组结构
	while (queue.length !== 0) {
		//当队列为空时就跳出循环
		let item = queue.shift() //取出队列中第一个元素
		data.push({
			code: item.code,
			name: item.name,
			ancestor: item.ancestor,
		})
		let children = item.childNodes // 取出该节点的孩子
		if (Array.isArray(children)) {
			// 塞入祖先菜单id， 在点击tag 打开top 菜单用到
			if (item.level === 1) {
				children = children.map(child => {
					return {
						...child,
						ancestor: item.id,
					}
				})
			} else if (item.ancestor) {
				children = children.map(child => {
					return {
						...child,
						ancestor: item.ancestor,
					}
				})
			}
			queue.push(...children)
		}
	}
	return data
}

// 所有所有叶子节点的菜单
export function getJuniorNode(tree) {
	let queue = []
	if (Array.isArray(tree)) {
		queue = [...tree]
	} else if ({}.toString.call(tree) === '[object Object]') {
		queue = [tree]
	}
	let data = [] //返回的数组结构
	while (queue.length !== 0) {
		//当队列为空时就跳出循环
		let item = queue.shift() //取出队列中第一个元素

		let children = item.childNodes // 取出该节点的子节点
		if (Array.isArray(children) && children.length > 0) {
			queue.push(
				...children.map(child => {
					if (child.level === 1) {
						child.ancestor = child.id
					} else if (child.level === 2) {
						child.ancestor = child.parentId
					} else {
						child.ancestor = item.ancestor
					}
					return child
				}),
			)
		} else {
			data.push({
				code: item.code,
				name: item.name,
				path: item.path,
				ancestor: item.ancestor,
			})
		}
	}
	return data
}
