import axios from 'axios'
import { baseUrl, ssoUrl, LOGIN_TYPE } from '@/config/env'
import interceptor from '@/http/interceptor'
import ssoInterceptor from '@/sso/interceptor'

const http = axios.create({
	baseURL: LOGIN_TYPE === 'sso' ? ssoUrl : baseUrl,
	timeout: 20000,
	validateStatus: status => status >= 200 && status <= 500,
})

if (LOGIN_TYPE === 'sso') {
	ssoInterceptor(http)
} else {
	interceptor(http)
}

export default http
