<template>
	<div class="navbar">
		<Hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" />
		<top-menu class="breadcrumb-container" />
		<div class="right-menu">
			<Bell v-if="showIM" class="right-menu-item" />
			<SwitchTheme class="right-menu-item" />
			<Screenfull class="right-menu-item hover-effect" />
			<Lock class="right-menu-item hover-effect" />
			<el-dropdown class="avatar-container right-menu-item hover-effect" trigger="hover">
				<div class="avatar-wrapper">
					<img v-if="avatar" :src="avatar" class="user-avatar" />
					<img
						v-else
						src="https://websitedn.yiautos.com/nrp4/main/default_avatar.png"
						class="user-avatar"
					/>
					<p class="nickname">{{ nickname }}</p>
					<arrow-down />
				</div>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item v-if="LOGIN_TYPE !== 'sso'" @click="toProfile">
							个人信息
						</el-dropdown-item>
						<el-dropdown-item divided @click="logout">
							<span style="display: block">退出登录</span>
						</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
import Hamburger from './Hamburger.vue'
import Screenfull from './Screenfull.vue'
import SwitchTheme from './SwitchTheme.vue'
import Lock from './Lock.vue'
import Bell from './Bell.vue'
import TopMenu from './TopMenu.vue'
import { computed, reactive, toRefs } from 'vue'
import { useAppStore } from '@/store/modules/app'
import { useUserStore } from '@/store/modules/user'
import { useRouter } from 'vue-router'
import { LOGIN_TYPE } from '@/config/env'
import { jumpLoginNoParam } from '@/sso'

export default {
	components: {
		TopMenu,
		Hamburger,
		Screenfull,
		SwitchTheme,
		Lock,
		Bell,
	},
	setup() {
		const appStore = useAppStore()
		const userStore = useUserStore()
		const router = useRouter()
		const sidebar = computed(() => {
			return appStore.sidebar
		})
		const avatar = computed(() => {
			return userStore.userInfo.avatar
		})
		const nickname = computed(() => {
			if (LOGIN_TYPE === 'sso') {
				return userStore.ssoUserInfo.realName || '管理员'
			}
			return userStore.userInfo.nick_name || '管理员'
		})
		const showIM = computed(() => {
			return userStore.showIM
		})
		const state = reactive({
			toProfile: () => {
				router.push('/profile')
			},
			logout: () => {
				userStore.logout()
				if (LOGIN_TYPE === 'sso') {
					jumpLoginNoParam()
				} else {
					router.push('/login')
				}
			},
		})
		return {
			sidebar,
			avatar,
			nickname,
			showIM,
			LOGIN_TYPE,
			...toRefs(state),
		}
	},
}
</script>
<style lang="less">
.hamburger-container {
	.yi-icon {
		color: #fff;
	}
}
</style>
<style lang="less" scoped>
.yi-dropdown-menu {
	a {
		text-decoration: none;
	}
}
.navbar {
	display: flex;
	justify-content: space-between;
	height: 60px;
	overflow: hidden;
	position: relative;
	background-color: var(--bg-color);
	transition: background-color 0.5s;
	box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

	.hamburger-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 100%;
		cursor: pointer;
		transition: background 0.3s;
		-webkit-tap-highlight-color: transparent;

		&:hover {
			background: rgba(0, 0, 0, 0.025);
		}
	}

	.breadcrumb-container {
		flex: 1;
	}

	.errLog-container {
		display: inline-block;
		vertical-align: top;
	}

	.right-menu {
		height: 100%;
		line-height: 60px;

		&:focus {
			outline: none;
		}

		.right-menu-item {
			display: inline-block;
			padding: 0 12px;
			height: 100%;
			font-size: 18px;
			vertical-align: text-bottom;
		}

		.avatar-container {
			.avatar-wrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 13px 0 0;
				cursor: pointer;
				.user-avatar {
					width: 34px;
					height: 34px;
					border-radius: 10px;
				}
				.nickname {
					max-width: 55px;
					margin: 0;
					top: 0;
					color: var(--text-color);
					flex: 1;
					font-size: 14px;
					padding-left: 10px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.icon {
					width: 30px;
					height: 18px;
					font-size: 18px;
					color: var(--text-color);
				}

				.yi-icon-caret-bottom {
					cursor: pointer;
					position: absolute;
					right: -20px;
					top: 25px;
					font-size: 12px;
				}
			}
		}
	}
}
</style>
