<template>
	<div class="bell-area" @click="handleClick">
		<el-badge :value="totalUnreadCount > 0 ? totalUnreadCount : ''" :max="99">
			<svg-icon icon-class="bell"></svg-icon>
		</el-badge>
		<yi-im ref="yiImRef" @setTotalUnreadCount="setTotalUnreadCount"></yi-im>
	</div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref } from 'vue'
import { titleNotify } from '@/utils/util'

export default defineComponent({
	setup() {
		const yiImRef = ref(null)
		const state = reactive({
			totalUnreadCount: 0,
			handleClick() {
				yiImRef.value.setImVisible()
			},
			setTotalUnreadCount(count) {
				state.totalUnreadCount = count
				titleNotify(count)
			},
		})

		return {
			yiImRef,
			...toRefs(state),
		}
	},
})
</script>
<style lang="less" scoped>
.bell-area {
	cursor: pointer;
	color: var(--text-color);
	transition: color 0.25s;
}
</style>
<style lang="less">
.bell-area {
	.svg-icon {
		font-size: 24px;
	}
	.yi-badge__content.is-fixed {
		top: 10px;
	}
}
</style>
