import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { router } from '@/router'
import { useUserStore } from '@/store/modules/user'
import { addTag } from '@/micro'
import whiteList from '@/router/whitelist.js'
import { useAppStore } from '@/store/modules/app'
import { LOGIN_TYPE } from '@/config/env'
import { jumpLogin } from '@/sso'
import { usePermissionStore } from '@/store/modules/permission'
import { useTagStore } from '@/store/modules/tag'
import { getHumpStr } from '@/utils/util'
import { readUrlAppToken, urlHasAppToken } from '@/sso'

NProgress.configure({ showSpinner: false })

router.beforeEach(async (to, from, next) => {
	NProgress.start()
	console.log('to', to, history.state, window.location)
	if (history.state && !history.state.current) {
		Object.assign(history.state, { current: from.fullPath })
	}

	const userStore = useUserStore()
	const permissionStore = usePermissionStore()
	const whitePaths = whiteList.map(route => route.path)

	// 如果是SSO登录模式, 先检查路由上是否有SsoToken
	if (LOGIN_TYPE === 'sso') {
		const ssoToken = urlHasAppToken()
		if (ssoToken) {
			readUrlAppToken()
			// 去除vue-router path 上appToken 参数
			Reflect.deleteProperty(to.query, 'appToken')
			next({
				...to,
				fullPath: to.fullPath
					.replace(/appToken=.{32}&?|\?appToken=.{32}(?!&)/, '')
					.replace(/(?<=#\/.*)((#\/)+)/, ''),
				replace: true,
			})
			return
		}
	}

	if (userStore.token) {
		if (!userStore.ssoUserInfo) {
			await userStore.setSsoUserInfo()
			await permissionStore.setMenus()
		}
		if (to.path === '/login') {
			next({ path: '/' })
			NProgress.done()
		} else if (whitePaths.includes(to.path) || to.name === '404') {
			const appStore = useAppStore()
			appStore.clearMicroApp()
			next()
		} else {
			// 当路径是旧链接的时候，会自动重定向到新的，所以该链接不需要打开一个 tag
			if (!to.path.includes('/#/qiankun/micro')) {
				if (LOGIN_TYPE === 'sso') {
					// tag 添加统一放到全局路由拦截中
					const tagStore = useTagStore()
					const currentNode = permissionStore.juniorNodes?.find(item => item.path === to.path)

					// 如果是 DC 平台，需要将 /yiautos-micro-cloud/dashboard 设置为工作台页面，则需要设置为 affix: true
					let isAffix = Boolean(to.meta.affix)
					if (process.env.VUE_APP_SSO_APP === 'DC') {
						isAffix = to.path === '/yiautos-micro-cloud/dashboard' || Boolean(to.meta.affix)
					}
					tagStore.addTag({
						title: to.meta.title || currentNode?.name || '暂无标题',
						code: getHumpStr(to.path),
						path: to.path,
						isActive: true,
						noCache: false,
						affix: isAffix,
					})
					if (currentNode?.ancestor) {
						permissionStore.setMenuId(currentNode.ancestor)
					}
				} else {
					addTag(to)
				}
			}
			next()
		}
	} else {
		console.log('无Token, 执行登录逻辑 ==>', LOGIN_TYPE)
		if (whitePaths.indexOf(to.path) !== -1 || to.name === '404') {
			next()
		} else {
			if (LOGIN_TYPE === 'sso') {
				jumpLogin()
			} else {
				next(`/login`)
			}
			// todo: 登录后路由重定向
			// next(`/login?redirect=${to.path}`)
			NProgress.done()
		}
	}
})

router.afterEach(() => {
	// Finish progress bar
	NProgress.done()

	// set page title
	// document.title = getPageTitle(to.meta.title)
})
