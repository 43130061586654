import request from '@/http'

export const getUserInfo = () => {
	// TODO 域名和api 的匹配
	let url = '/api/yiautos-sso-user/userInfo/currentUserInfo'
	if (process.env.VUE_APP_CONFIG_ENV === 'dev') {
		url = '/yiautos-sso-user/userInfo/currentUserInfo'
	}
	return request({
		url,
		method: 'POST',
	})
}

export const getApplicationByCode = () => {
	// TODO 域名和 api 的匹配
	let url = '/api/yiautos-sso-resource/application/findByCode'
	if (process.env.VUE_APP_CONFIG_ENV === 'dev') {
		url = '/yiautos-sso-resource/application/findByCode'
	}
	return request({
		url,
		method: 'get',
	})
}

export const listMenuByUser = () => {
	// TODO 域名和 api 的匹配
	let url = '/api/yiautos-sso-resource/roleMenu/listByUser'
	if (process.env.VUE_APP_CONFIG_ENV === 'dev') {
		url = '/yiautos-sso-resource/roleMenu/listByUser'
	}
	return request({
		url,
		method: 'get',
	})
}
