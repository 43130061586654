export const Keys = {
	sidebarStatusKey: 'vue3-typescript-admin-sidebarStatusKey',
	languageKey: 'vue3-typescript-admin-languageKey',
	sizeKey: 'vue3-typescript-admin-sizeKey',
	tokenKey: 'saber-access-token',
	ssoTokenKey: 'sso-access-token',
	ssoCompanyId: 'sso-company-id',
	ssoAppCode: 'sso-app-code',
	ssoAuthType: 'sso-auth-type',
	refreshTokenKey: 'saber-refresh-token',
	aseKey: 'vue3-typescript-admin-ase-key',
}
