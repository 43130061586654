import { defineStore } from 'pinia'
import { getStore, setStore, removeStore } from '@/utils/store'
import {
	setToken,
	setSsoToken,
	setSsoAuthType,
	setSsoAppCode,
	setSsoCompanyId,
	removeToken,
	setRefreshToken,
	removeRefreshToken,
} from '@/utils/cookies'
import { useAppStore } from './app'
import { useTagStore } from './tag'
import { usePermissionStore } from './permission'
import { getButtons } from '@/api/user'
import { LOGIN_TYPE } from '@/config/env'
import { getUserInfo } from '@/api/sso/common'

const SSO_APP_NAME = process.env.VUE_APP_SSO_APP
const SSO_AUTH_TYPE = process.env.VUE_APP_SSO_TYPE

export const useUserStore = defineStore({
	id: 'user',
	state: () => ({
		token: getStore({ name: 'token' }) || '',
		tokenExpire: getStore({ name: 'token-expire' }) || '',
		refreshToken: getStore({ name: 'refreshToken' }) || '',
		tenantId: getStore({ name: 'tenantId' }) || '',
		userInfo: getStore({ name: 'userInfo' }) || {},
		ssoUserInfo: null,
		permission: getStore({ name: 'permission' }) || {},
		showIM: getStore({ name: 'permission' })?.chat_tim || false,
	}),
	actions: {
		setToken(token) {
			this.token = token
			if (LOGIN_TYPE === 'sso') {
				setSsoToken(token)
				setSsoAuthType(SSO_AUTH_TYPE)
				setSsoAppCode(SSO_APP_NAME)
			} else {
				setToken(token)
			}
			setStore({ name: 'token', content: token })
		},
		clearToken() {
			this.token = ''
			removeToken()
			removeStore({ name: 'token' })
		},
		setExpire(expireTime) {
			this.tokenExpire = expireTime
			setStore({ name: 'token-expire', content: expireTime })
		},
		clearExpire() {
			this.tokenExpire = ''
			removeStore({ name: 'token-expire' })
		},
		setRefreshToken(refreshToken) {
			this.refreshToken = refreshToken
			setRefreshToken(refreshToken)
			setStore({ name: 'refreshToken', content: refreshToken })
		},
		clearRefreshToken() {
			this.refreshToken = ''
			removeRefreshToken()
			removeStore({ name: 'refreshToken' })
		},
		setTenantId(tenantId) {
			this.tenantId = tenantId
			setStore({
				name: 'tenantId',
				content: tenantId,
			})
		},
		clearTenantId() {
			this.tenantId = ''
			removeStore({ name: 'tenantId' })
		},
		setUserInfo(userInfo) {
			this.userInfo = userInfo
			setStore({
				name: 'userInfo',
				content: userInfo,
			})
		},
		clearUserInfo() {
			this.userInfo = {}
			removeStore({ name: 'ssoUserInfo' })
		},
		async setSsoUserInfo() {
			try {
				const { code, msg, data = {} } = (await getUserInfo()) || {}
				if (code !== 200) throw new Error(msg)
				this.ssoUserInfo = data
				setSsoCompanyId(data.companyId)
				setStore({
					name: 'ssoUserInfo',
					content: data,
				})
			} catch (err) {
				console.log('setSsoUserInfo', err)
			}
		},
		clearSsoUserInfo() {
			this.ssoUserInfo = {}
			removeStore({ name: 'ssoUserInfo' })
		},
		logout() {
			this.clearToken()
			this.clearExpire()
			this.clearRefreshToken()
			this.clearTenantId()
			this.clearUserInfo()
			this.clearSsoUserInfo()

			const permissionStore = usePermissionStore()
			permissionStore.clearMenus()
			permissionStore.clearRoutes()
			permissionStore.clearMenuId()

			const tagStore = useTagStore()
			tagStore.deleteAllTags()

			const appStore = useAppStore()
			appStore.clearLock()
			appStore.clearMicroApp()
		},
		getButtons() {
			return new Promise(resolve => {
				getButtons().then(res => {
					const permission = res.data

					let result = []
					function getCode(list) {
						list.forEach(ele => {
							if (typeof ele === 'object') {
								const children = ele.children
								const code = ele.code
								if (children) {
									getCode(children)
								} else {
									result.push(code)
								}
							}
						})
					}

					getCode(permission)
					this.permission = {}
					result.forEach(ele => {
						this.permission[ele] = true
					})

					if (this.permission.chat_tim) {
						this.showIM = true
					}

					setStore({
						name: 'permission',
						content: this.permission,
					})
					resolve()
				})
			})
		},
	},
})
