<template>
	<router-view />
</template>

<script>
import { defineComponent } from 'vue'
import { useDictStore } from '@/store/modules/dict'
import { LOGIN_TYPE } from './config/env'

export default defineComponent({
	setup() {
		const dictStore = useDictStore()
		if (LOGIN_TYPE !== 'sso') {
			dictStore.fetchAllEnums()
		}
	},
})
</script>

<style lang="less">
html,
body {
	height: 100%;
	margin: 0;
}
#app {
	height: 100%;
}
</style>
