let baseUrl = ''
let ssoUrl = ''
let wsUrl = ''
const env = process.env
const LOGIN_TYPE = env.VUE_APP_LOGIN_TYPE

const urlList = {
	dev: '//183.251.101.194:8081',
	test: '//api.test.itsmycar.cn',
	pre: '//api.pre.itsmycar.cn',
	prod: '//api.yiautos.com',
}

baseUrl = urlList[env.VUE_APP_CONFIG_ENV]
let protocol = 'http:'
if (window.location.protocol.includes('s')) {
	protocol = 'https:'
}
baseUrl = `${protocol}${baseUrl}`

// socket连接地址
const wsUrlList = {
	dev: '//192.168.30.218:30064',
	test: '//ws.test.itsmycar.cn',
	pre: '//ws.pre.itsmycar.cn',
	prod: '//ws.nrp.yiautos.com',
}
wsUrl = wsUrlList[env.VUE_APP_CONFIG_ENV]
let wsProtocol = 'ws:'
if (window.location.protocol.includes('s')) {
	wsProtocol = 'wss:'
}
wsUrl = `${wsProtocol}${wsUrl}`

// sso登录地址
const ssoUrlList = {
	dev: '//ssoapi.dev.itsmycar.cn',
	test: '//sso.test.itsmycar.cn',
	pre: '//sso.pre.itsmycar.cn',
	prod: '//sso.yiautos.com',
}

ssoUrl = ssoUrlList[env.VUE_APP_CONFIG_ENV]
ssoUrl = `${protocol}${ssoUrl}`

export { baseUrl, env, wsUrl, ssoUrl, LOGIN_TYPE }
