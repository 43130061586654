import mitt from 'mitt'

// export const RESET_ROUTER_VIEW = 'RESET_ROUTER_VIEW'
// export const MICRO_APP_MSG = 'MICRO_APP_MSG'
// export const IM_LOGOUT = 'IM_LOGOUT'
export const MICRO_APP_401 = 'MICRO_APP_401'
export const MICRO_APP_TOKEN_EXPIRE = 'MICRO_APP_TOKEN_EXPIRE'
export const MICRO_APP_JUMP = 'MICRO_APP_JUMP'
export const MICRO_APP_CONSUMER = 'MICRO_APP_CONSUMER'
export const MICRO_SERVER_ERROR = 'MICRO_SERVER_ERROR'

const EventBus = mitt()
EventBus.$emit = EventBus.emit
EventBus.$on = EventBus.on
EventBus.$off = EventBus.off

window._EVENT_BUS = EventBus
window._CONSUMER_MESSAGE = {}

export default EventBus
