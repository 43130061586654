<template>
	<div :class="classObj" class="app-wrapper">
		<Sidebar class="sidebar-container" />
		<div class="main-container">
			<div>
				<Navbar />
				<TagsView />
			</div>
			<div class="app-main">
				<div class="am-layout" id="am_layout" v-loading="loading">
					<div v-show="!$route.name" class="h100">
						<div
							v-for="item in MICRO_CONFIG"
							v-show="item === microName"
							:key="item"
							:id="item"
							class="am-layout-container"
						></div>
					</div>
					<div v-show="$route.name" class="h100">
						<router-view :key="microName"></router-view>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, watch, defineComponent, onBeforeUnmount, onMounted, reactive, toRefs } from 'vue'
import EventBus, {
	MICRO_APP_401,
	MICRO_APP_TOKEN_EXPIRE,
	MICRO_APP_JUMP,
	MICRO_APP_CONSUMER,
	MICRO_SERVER_ERROR,
} from '@/event/EventBus'
import { useAppStore } from '@/store/modules/app'
import { useTagStore } from '@/store/modules/tag'
import { useUserStore } from '@/store/modules/user'
import { Navbar, TagsView, Sidebar } from './components'
import { removeStore } from '@/utils/store'
import { debounce } from '@/utils/util'
import MICRO_CONFIG from '@/config/micro'
import { useRoute, useRouter } from 'vue-router'
import whitelist from '@/router/whitelist.js'
import { ElMessage } from 'element-plus'
import { loadApp } from '@/micro'
import { getNewToken } from '@/http/interceptor'

export default defineComponent({
	name: 'Layout',
	components: {
		Navbar,
		Sidebar,
		TagsView,
	},
	setup() {
		let setIntervalId = null
		let intervarCount = 0
		const intervalDelay = 300
		const route = useRoute()
		const router = useRouter()
		const userStore = useUserStore()
		const appStore = useAppStore()
		const tagStore = useTagStore()
		const state = reactive({
			microName: '',
			loading: false,
		})
		const sidebar = computed(() => {
			return appStore.sidebar
		})
		const classObj = computed(() => {
			return {
				hideSidebar: !sidebar.value.opened,
				openSidebar: sidebar.value.opened,
				withoutAnimation: sidebar.value.withoutAnimation,
			}
		})
		const cachedViews = computed(() => {
			return tagStore.cachedViews
		})
		function setMicroName(path) {
			state.microName = getMicroName(path)
		}
		function getMicroName(path) {
			return path.substring(1, path.indexOf('/', 1))
		}
		function isolateStyle(newPath, oldPath) {
			// 首次加载或者刷新浏览器时没有旧的路由，此时不需要处理样式隔离问题
			if (!oldPath) return

			const newMicroName = getMicroName(newPath)
			const oldMicroName = getMicroName(oldPath)

			// 路由跳转前后都是同一个子应用则不需要处理样式隔离问题
			if (newMicroName === oldMicroName) return
			if (newMicroName === '/') {
				setStyleType(oldMicroName, true)
				return
			}
			if (oldMicroName === '/') {
				setStyleType(newMicroName, false)
				return
			}

			setStyleType(oldMicroName, true)
			setStyleType(newMicroName, false)
		}
		function setStyleType(rootId, isDisabled) {
			if (rootId === '/') return
			const root = document.getElementById(rootId)
			root.querySelectorAll('style').forEach(style => {
				style.type = isDisabled ? 'disabled-css' : 'text/css'
			})
		}
		watch(
			() => route.path,
			(n, o) => {
				const paths = whitelist.map(route => route.path)
				if (paths.includes(n) || route.name === '404') return
				// 隔离子应用间的样式
				isolateStyle(n, o)

				setMicroName(n)

				// 加载子应用
				loadApp(route)
			},
			// 刷新浏览器的时候需要执行
			{ immediate: true },
		)
		// 首次加载子应用的时候加上 loading 效果，且超过6秒后直接提示加载失败
		watch(
			() => state.microName,
			n => {
				if (n === '/') {
					state.loading = false
					return
				}
				const count = tagStore.visitedViews.reduce((acc, cur) => {
					if (cur.path.includes(n)) {
						acc++
					}
					return acc
				}, 0)
				// 切换到一个路由，这个路由也会先加入到 visitedViews 这个 store 里，
				// 所以如果 count = 1 说明是首次加载某个子应用
				if (count > 1) return
				clearInterval(setIntervalId)
				setIntervalId = null
				intervarCount = 0
				state.loading = true
				setIntervalId = setInterval(function () {
					// 如果页面没加载出来会一直轮询，加载 6 秒后，会直接提示失败
					const ele = document.getElementById(state.microName)
					if (ele && ele.childElementCount > 0) {
						state.loading = false
						clearInterval(setIntervalId)
						setIntervalId = null
						intervarCount = 0
					} else if (intervarCount > 15) {
						console.error(`微应用 ${n} 加载失败，重载页面中`)
						ElMessage.error({
							message: `微应用 ${n} 加载失败，重载页面中`,
						})
						state.loading = false
						clearInterval(setIntervalId)
						setIntervalId = null
						intervarCount = 0

						setTimeout(() => {
							window.location.reload()
						}, 1000)
					} else {
						intervarCount++
					}
				}, intervalDelay)
			},
		)

		onMounted(() => {
			const { path } = route
			setMicroName(path)
			// 页面刷新的时候需要清空缓存的 cache-tags
			window.addEventListener('beforeunload', () => {
				removeStore({ name: 'cache-tags' })
			})

			EventBus.$on(MICRO_APP_401, onMicroApp401)
			EventBus.$on(MICRO_APP_TOKEN_EXPIRE, onMicroAppTokenExpire)
			EventBus.$on(MICRO_SERVER_ERROR, onMicroServerError)
			EventBus.$on(MICRO_APP_JUMP, onMicroEventJump)
			EventBus.$on(MICRO_APP_CONSUMER, onMicroEventConsumer)
		})
		function onMicroApp401() {
			console.log('主应用收到微应用401事件')
			return debounce(function () {
				userStore.logout()
				router.push('/login')
			}, 1000)()
		}
		function onMicroAppTokenExpire() {
			console.log('主应用收到微应用token过期事件')
			return debounce(function () {
				getNewToken()
			}, 1000)()
		}
		// 拍平对象
		function flatObj(obj) {
			let ret = {}
			for (let k in obj) {
				if (Object.prototype.toString.call(obj[k]) === '[object Object]') {
					return { ...ret, ...flatObj(obj[k]) }
				} else {
					ret = { ...ret, [k]: obj[k] }
				}
			}
			return ret
		}
		function onMicroEventJump({ data = {} }) {
			console.log('接收到跳转消息 ==>', data.query)
			const path = data.src.replace('ymicros:/', '').replace('ymicro:/', '')
			delete data.src
			router.push({
				path,
				query: flatObj(data),
			})
		}
		function onMicroEventConsumer(event) {
			const { url, data, subject, name } = event
			console.log('接收到微应用消费者信息 ==>', event)
			// 如果需要消费者, 则增加至消费者队列中
			if (subject && data) {
				window._CONSUMER_MESSAGE[subject] = data
			}
			const path = url.replace('ymicros:/', '').replace('ymicro:/', '')
			router.push({
				path,
				query: { name },
			})
		}
		// 当微应用检测到服务端异常时弹出错误弹框
		function onMicroServerError(event) {
			ElMessage.error({
				dangerouslyUseHTMLString: true,
				message: `<p>traceId: ${event.tradeId}</p><p>stackTrace: ${event.stackTrace}</p>`,
			})
		}
		onBeforeUnmount(() => {
			// removeEventListenerResize()
			clearInterval(setIntervalId)
			setIntervalId = null
			intervarCount = 0

			EventBus.$off(MICRO_APP_401, onMicroApp401)
			EventBus.$off(MICRO_APP_TOKEN_EXPIRE, onMicroAppTokenExpire)
			EventBus.$off(MICRO_APP_JUMP, onMicroEventJump)
			EventBus.$off(MICRO_SERVER_ERROR, onMicroServerError)
			EventBus.$off(MICRO_APP_CONSUMER, onMicroEventConsumer)
		})
		return {
			classObj,
			sidebar,
			cachedViews,
			MICRO_CONFIG: Object.keys(MICRO_CONFIG),
			...toRefs(state),
		}
	},
})
</script>
<style lang="less">
.am-layout-container {
	height: 100%;
	& > div {
		height: 100%;
	}
}
.app-main {
	.el-loading-parent--relative {
		position: relative !important;
	}
	.el-loading-mask {
		position: absolute;
		z-index: 2000;
		background-color: rgba(255, 255, 255, 0.9);
		margin: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: opacity var(--el-transition-duration);
	}
	.el-loading-spinner {
		top: 50%;
		margin-top: calc((0px - var(--el-loading-spinner-size)) / 2);
		width: 100%;
		text-align: center;
		position: absolute;
	}
	.el-loading-spinner .circular {
		display: inline;
		height: var(--el-loading-spinner-size);
		width: var(--el-loading-spinner-size);
		animation: loading-rotate 2s linear infinite;
	}
	.el-loading-spinner .path {
		animation: loading-dash 1.5s ease-in-out infinite;
		stroke-dasharray: 90, 150;
		stroke-dashoffset: 0;
		stroke-width: 2;
		stroke: var(--el-color-primary);
		stroke-linecap: round;
	}
}
</style>
<style lang="less" scoped>
.app-wrapper {
	.clearfix;
	position: relative;
	height: 100%;
	width: 100%;
}
.app-main {
	width: 100%;
	height: calc(100% - 110px);
	overflow-y: auto;
	overflow-x: hidden;
	box-sizing: border-box;
	padding: 10px;
	background-color: #f0f2f5;
}
.am-layout {
	position: relative;
	background-color: white;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 16px;
	.h100 {
		height: 100%;
	}
}

.drawer-bg {
	background: #000;
	opacity: 0.3;
	width: 100%;
	top: 0;
	height: 100%;
	position: absolute;
	z-index: 999;
}

.main-container {
	height: 100%;
	min-height: 100%;
	transition: margin-left 0.25s;
	margin-left: @sideBarWidth;
	position: relative;
}

.sidebar-container {
	width: @sideBarWidth !important;
	height: 100%;
	position: fixed;
	font-size: 0px;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	overflow: hidden;
	background-color: var(--bg-color);
	transition: background-color 0.5s, width 0.25s;
}

.fixed-header {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 9;
	width: calc(100% - @sideBarWidth);
	transition: width 0.28s;
}

.hideSidebar {
	.main-container {
		margin-left: 64px;
	}

	.sidebar-container {
		width: 64px !important;
	}

	.fixed-header {
		width: calc(100% - 64px);
	}
}
</style>
