<template>
	<div>
		<template v-if="!item.childNodes || item.childNodes.length === 0">
			<SidebarItemLink :to="item">
				<el-menu-item :index="item.path">
					<template #title>
						<span>{{ item.name }}</span>
					</template>
				</el-menu-item>
			</SidebarItemLink>
		</template>
		<el-sub-menu v-else :index="item.path">
			<template #title>
				<svg-icon icon-class="folder"></svg-icon>
				<span>{{ item.name }}</span>
			</template>
			<SidebarItem
				v-for="(child, cindex) in item.childNodes"
				:key="child.id"
				:item="child"
				:base-path="child.path"
			/>
		</el-sub-menu>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import SidebarItemLink from './SidebarItemLink.vue'
export default defineComponent({
	props: {
		item: {
			type: Object,
			required: true,
		},
		basePath: {
			type: String,
			required: true,
		},
	},
	components: {
		SidebarItemLink,
	},
	setup() {},
})
</script>
<style lang="less">
.yi-menu--collapse .yi-sub-menu.is-active .yi-sub-menu__title {
	color: var(--text-active-color) !important;
}
.simple-mode {
	.yi-sub-menu {
		& > .yi-sub-menu__title {
			.yi-sub-menu__icon-arrow {
				display: none;
			}
		}
	}
}
.yi-sub-menu .yi-sub-menu__title {
	color: var(--text-color);
	transition: color 0.25s;
	font-weight: 600;
}
.yi-sub-menu .yi-menu {
	transition: background-color 0.5s;
}
</style>
<style lang="less" scoped>
.yi-menu-item,
.yi-sub-menu {
	transition: color 0.25s, background-color 0.5s;
	.svg-icon {
		margin-right: 5px;
		font-size: 16px;
	}
}
.yi-menu--collapse .yi-menu-item .svg-icon,
.yi-menu--collapse .yi-sub-menu .svg-icon {
	margin: 0;
}
.yi-menu-item:hover,
.yi-submenu__title:hover {
	color: var(--text-active-color);
	background-color: var(--el-menu-hover-bg-color);
}
.yi-menu-item.is-active {
	color: var(--text-active-color);
	font-weight: 600;
	background-color: var(--el-menu-active-color);
}
.simple-mode {
	.submenu-title-noDropdown {
		padding: 0 !important;
		position: relative;

		.yi-tooltip {
			padding: 0 !important;
		}
	}

	.yi-sub-menu {
		overflow: hidden;

		& > .yi-sub-menu__title {
			padding: 0px !important;

			.yi-sub-menu__icon-arrow {
				display: none;
			}
			.yi-icon {
				margin-right: 0;
			}

			& > span {
				width: 0;
				visibility: hidden;
			}
		}
	}
}
</style>
