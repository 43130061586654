import { createApp } from 'vue'
import App from './App.vue'
import { setupStore } from '@/store'
import { setupRouter, router } from '@/router'
import { registerGlobComp } from '@/components/registerGlobComp'
import '@/router/router-interceptor'
import 'element-plus/dist/index.css'
import '@/styles/index.less'

async function bootstrap() {
	const app = createApp(App)

	// 配置 store
	setupStore(app)

	// 注册全局组件
	registerGlobComp(app)

	// 配置路由
	setupRouter(app)

	// https://next.router.vuejs.org/api/#isready
	await router.isReady()

	app.mount('#app', true)
}

bootstrap()
