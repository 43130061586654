import { defineStore } from 'pinia'
import { getStore, setStore, removeStore } from '@/utils/store'
import { listMenuByUser } from '@/api/sso/common'
import { ElMessage } from 'element-plus'
import { getJuniorNode } from '@/sso'

function resetPath(data) {
	const routes = data.map(item => {
		item.path = item.url
			.replace('ymicros:/', '')
			.replace('ymicro:/', '')
			.replace('/yiautos-micro-psf/', '/yiautos-micro-psfs/')
		if (item.childNodes && item.childNodes.length > 0) {
			item.childNodes = resetPath(item.childNodes)
		}
		return item
	})

	return routes
}

export const usePermissionStore = defineStore({
	id: 'permission',
	state: () => ({
		menus: [], // 顶部子应用菜单
		menuId: {}, // 当前激活的菜单
		routes: [], // 侧边栏路由
		juniorNodes: [], // 所有叶子节点的菜单
	}),
	actions: {
		async setMenus() {
			try {
				const { code, data, msg } = (await listMenuByUser()) || {}
				if (code !== 200) throw new Error(msg)
				const menus = resetPath(data?.childNodes || [])
				this.juniorNodes = getJuniorNode(menus)
				this.menus = menus
				this.setMenuId(menus[0]?.id)
				return Promise.resolve(data)
			} catch (error) {
				ElMessage.error(error.message)
			}
		},
		clearMenus() {
			this.menus = []
			removeStore({ name: 'menus' })
		},
		setMenuId(menuId) {
			this.menuId = menuId
			this.setRoutes(menuId)
		},
		clearMenuId() {
			this.menuId = {}
			removeStore({ name: 'menuId' })
		},
		setRoutes(menuId) {
			if (!menuId) {
				return
			}
			this.routes = this.menus.find(menu => menu.id === menuId)?.childNodes || []
		},
		setSsoRoutes(menu) {
			try {
				const routes = resetPath(menu.children)
				console.log('当前侧边栏菜单 ==>', routes)
				this.routes = routes
				setStore({ name: 'routes', content: routes })
			} catch (error) {
				ElMessage.error(error.message)
			}
		},
		clearRoutes() {
			this.routes = []
			removeStore({ name: 'routes' })
		},
	},
})
