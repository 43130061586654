import request from '@/http'
import md5 from 'js-md5'

// 检查当前用户密码是否需要修改
export const checkPasswordResetStatus = function (userId) {
	return request({
		url: '/api/blade-user/check-password',
		method: 'get',
		params: {
			userId,
		},
	})
}

// 发送短信验证码用于修改密码
export const sendSmsCodeMessage = function (mobile) {
	return request({
		url: '/api/blade-user/user/sms/send-message',
		method: 'get',
		params: {
			mobile,
		},
		headers: {
			'Tenant-Id': process.env.VUE_APP_TENANT_ID,
		},
	})
}

// 确认修改密码
export const confirmResetPwd = function (data) {
	return request({
		url: '/api/blade-user/update-password-forget',
		method: 'post',
		// 此处不应用params明文传输, 应该为post
		data: {
			...data,
			newPassword: md5(data.newPassword),
			newPassword1: md5(data.newPassword1),
		},
		headers: {
			'Tenant-Id': process.env.VUE_APP_TENANT_ID,
		},
	})
}

export const loginByUsername = (tenantId, username, password, type, key, code) =>
	request({
		url: '/api/blade-auth/oauth/token',
		method: 'post',
		headers: {
			'Tenant-Id': tenantId,
			'Captcha-Key': key,
			'Captcha-Code': code,
		},
		params: {
			tenantId,
			username,
			password,
			grant_type: 'password',
			scope: 'all',
			type,
		},
	})

export const refreshToken = (refresh_token, tenantId) =>
	request({
		url: '/api/blade-auth/oauth/token',
		method: 'post',
		headers: {
			'Tenant-Id': tenantId,
		},
		params: {
			tenantId,
			refresh_token,
			grant_type: 'refresh_token',
			scope: 'all',
		},
	})

export const getButtons = () =>
	request({
		url: '/api/blade-system/menu/buttons',
		method: 'get',
	})

export const getUserInfo = () =>
	request({
		url: '/api/blade-auth/oauth/user-info',
		method: 'get',
	})
