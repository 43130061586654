import axios from 'axios'
import { serialize } from '@/utils/util'
import { getToken, getRefreshToken } from '@/utils/cookies'
import { ElMessage } from 'element-plus'
import { router } from '@/router'
import { getStore } from '@/utils/store'
import website from '@/config/website'
import { Base64 } from 'js-base64'
import { refreshToken } from '@/api/user'
import { useUserStore } from '@/store/modules/user'

let isFirstTokenExpire = true
let requestTimer = null

function interceptor(http) {
	http.interceptors.request.use(
		config => {
			const meta = config.meta || {}
			const isToken = meta.isToken === false

			config.headers['Authorization'] = `Basic ${Base64.encode(
				`${website.clientId}:${website.clientSecret}`,
			)}`
			// 让每个请求携带token
			if (getToken() && !isToken) {
				config.headers[website.tokenHeader] = 'bearer ' + getToken()
			}
			// headers中配置text请求
			if (config.text === true) {
				config.headers['Content-Type'] = 'text/plain'
			}
			// headers中配置serialize为true开启序列化
			if (config.method === 'post' && meta.isSerialize === true) {
				config.data = serialize(config.data)
			}
			config?.params?.grant_type !== 'refresh_token' && tokenIsExpired(config)

			return config
		},
		error => {
			return Promise.reject(error)
		},
	)
	http.interceptors.response.use(
		async res => {
			const userStore = useUserStore()
			// 获取状态码
			const status = res.data.code || res.status
			const message =
				res.data.msg || res.data.error_description || res.error_description || '未知错误'
			// 如果在白名单里则自行catch逻辑处理

			if (status === 401) {
				const requestUrl = res.config.url
				if (requestUrl.indexOf('blade-auth/oauth/token') != -1) {
					userStore.logout()
					router.push({ path: '/login' })
					return res
				}
				const config = res.config
				const _refreshToken = getRefreshToken()
				const tenantId = getStore({ name: 'tenantId' })
				const responsData = await refreshToken(_refreshToken, tenantId)
				if (responsData.data && responsData.data.access_token) {
					userStore.setToken(responsData.data.access_token)
					const expireTime = responsData.data.expires_in * 1000 + Date.now()
					userStore.setExpire(expireTime)
					return axios.request(config)
				} else {
					ElMessage.error('刷新token失败')
					userStore.logout()
					router.push({ path: '/login' })
				}
			}
			if (status === 404) {
				ElMessage.error('服务器开小差，请稍后重试')
				return
			}
			// 如果请求为非200否者默认统一处理
			if (status !== 200) {
				return Promise.reject(new Error(message))
			}
			return res.data
		},
		error => {
			return Promise.reject(new Error(error))
		},
	)
}

export const getNewToken = async config => {
	try {
		const userStore = useUserStore()
		const _refreshToken = getRefreshToken()
		const tenantId = getStore({ name: 'tenantId' })
		const res = await refreshToken(_refreshToken, tenantId)
		if (res && res.access_token) {
			userStore.setToken(res.access_token)
			const expireTime = res.expires_in * 1000 + Date.now()
			userStore.setExpire(expireTime)
			if (!config) {
				ElMessage.success('token 刷新成功，正在重载页面')
				setTimeout(() => {
					window.location.reload()
				}, 1000)
			} else {
				// 主应用内部token过期，则自动重发上次请求，如果是微应用里过期，则提示用户重新操作
				config.headers[website.tokenHeader] = 'bearer ' + getToken()
				console.warn('token 刷新成功，正在自动重发上次请求')
				axios.request(config)
			}
		} else {
			ElMessage.error('token 刷新失败，请重新登录')
			userStore.logout()
			router.push({ path: '/login' })
		}
	} catch (err) {
		console.log('getNewToken error =>', err)
	}
}

function tokenIsExpired(config) {
	// 判断是否存在token，且当前时间 < token过期时间 - 10min
	const bufferTime = 600000
	const expire = getStore({ name: 'token-expire' })
	const currentTime = Date.now()
	if (expire) {
		if (currentTime >= expire - bufferTime) {
			console.warn('主应用接口token过期，正在自动刷新token')
			requestTimer && clearTimeout(requestTimer)
			if (isFirstTokenExpire) {
				isFirstTokenExpire = false
				// 去刷新token
				getNewToken(config)
			}
			requestTimer = setTimeout(() => {
				isFirstTokenExpire = true
			}, 1000)
			return true
		}
		return false
	} else {
		return false
	}
}

export default interceptor
