<template>
	<div class="sidebar-logo-container" :class="{ collapse: isCollapse }">
		<transition name="fade">
			<div v-show="!isCollapse">
				<router-link key="expand" class="sidebar-logo-link" to="/">
					<img
						src="https://websitedn.yiautos.com/nrp4/main/logo_40_40.png"
						class="sidebar-logo"
						style="width: 40px; height: 40px"
					/>
				</router-link>
			</div>
		</transition>
		<transition name="fade">
			<div v-show="isCollapse">
				<router-link key="expand" class="sidebar-logo-link" to="/">
					<img
						v-show="theme === 'dark'"
						src="https://websitedn.yiautos.com/nrp4/main/logo_86_40_white.png"
						class="sidebar-logo"
					/>
					<img
						v-show="theme === 'light'"
						src="https://websitedn.yiautos.com/nrp4/main/logo_86_40_black.png"
						class="sidebar-logo"
					/>
					<h1 class="sidebar-title">· {{ platformName }}</h1>
				</router-link>
			</div>
		</transition>
	</div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useAppStore } from '@/store/modules/app'
import website from '@/config/website'
import { LOGIN_TYPE } from '@/config/env'

export default defineComponent({
	props: {
		isCollapse: {
			type: Boolean,
			default: true,
		},
	},
	setup() {
		const title = website.title
		const appStore = useAppStore()
		const theme = computed(() => {
			return appStore.theme
		})
		return {
			title,
			theme,
			platformName: LOGIN_TYPE === 'sso' ? '开发者平台' : '新零售平台',
		}
	},
})
</script>

<style lang="less" scoped>
.fade-leave-active {
	transition: opacity 0.2s;
}
.fade-enter-active {
	transition: opacity 2.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.sidebar-logo-container {
	position: relative;
	width: 100%;
	height: 60px;
	line-height: 60px;
	overflow: hidden;
	& .sidebar-logo-link {
		height: 100%;
		width: 100%;

		& .sidebar-logo {
			width: 86px;
			height: 40px;
			vertical-align: middle;
			margin: 0 12px;
		}

		& .sidebar-title {
			display: inline-block;
			margin: 0;
			color: var(--text-color);
			transition: color 0.25s;
			font-weight: 300;
			line-height: 60px;
			font-size: 18px;
			font-family: PingFangSC-Semibold, Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
			vertical-align: middle;
		}
	}
}
</style>
